import { Routes, Route } from "react-router-dom";

import Store from "./components/store.jsx";
import Login from "./components/admin/login.jsx";
import Dashboard from "./components/admin/dashboard.jsx";
import Navbar from "./components/navbar/navbar.jsx";
import Footer from "./components/footer/footer.jsx";
import Help from "./components/help/helpRoutes.jsx";
import HoverFooter from "./components/hoverFooter/hoverFooter.jsx";

function App() {
  return (
    <div>
      <div id="notifs-contatiner">
        
      </div>
      <Routes>
        <Route path="/*" element={
          <div className="store-div">
            <Navbar />
            <HoverFooter />
            <Store />
            <Footer />
          </div>
        } />
        <Route path="/.well-known/apple-developer-merchantid-domain-association"/>
        <Route path="/help/*" element={<Help />} />
        <Route path="/admin" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </div>
  );
}

export default App;