import "./navbar.css"

import logo from '../../images/logo.webp'

import { Link } from "react-router-dom";
import { AppConstants } from "../../constants/AppConstants";

function Navbar()
{
    return(
        <div id="navbar-cont" className="n-b-cont">
            <div className="n-b-flex">
                <nav className="mc-nav-bar">
                    <Link to={"/"}>
                        <div className="mc-nav-bar-name">
                            <img className="store-logo" src={logo} alt="greatalts logo"/>
                            <div className="mc-nav-bar-n-c">
                                <div>Great Alts</div>
                                <span>Best Minecraft Alt Shop</span>
                            </div>
                        </div>
                    </Link>
                    <div className="mc-nav-bar-main">
                        <a href={AppConstants.DISCORD_REGULAR_INVITE} className="discord-a">
                            <div className="mc-nav-bar-main-item">Support</div>
                        </a>
                        <a href="https://www.trustpilot.com/review/altsgreat.com" className="reviews-a">
                            <div className="mc-nav-bar-main-item">Reviews</div>
                        </a>
                        <Link to={"/help/terms-of-service"}>
                            <div className="mc-nav-bar-main-item">Legal</div>
                        </Link>
                    </div>
                </nav>
                <nav className="pn-nav-bar">
                    <ol>
                        <li><a href="#mc-fa">Minecraft FA</a></li>
                        <li><a href="#hypixel-fas">Hypixel FA</a></li>
                        <li><a href="#hypixel-temp">Temporary Alts</a></li>
                        <li><a href="#skyblock">Skyblock</a></li>
                        <li><a href="#capes">Capes</a></li>
                        <li><a href="#vpns">VPNs</a></li>
                        <li id="discord-nav"><a id="discord-nav" href="#discord">Nitro</a></li>
                    </ol>
                </nav>
            </div>
        </div>
    )
}

export default Navbar;