import api from "./apiConfig";
import { RouteConstants } from "../constants/AppConstants";

export const getUser = async () => {
  try{
    const response = await api.get(RouteConstants.USERS_URL)
    return response.data;
  }catch(error){
    throw error;
  }
}

export const loginUser = async (userData) => {
    try{
      const response = await api.get(`${RouteConstants.LOGIN_API_URL}`, userData);
      return response;
    }catch (error) {
      throw error;
    }
}