import { useState, useEffect } from 'react';

import { ShopProducts } from "../../constants/AccountsInfo"
import { Helmet } from 'react-helmet';
import { addNotif } from "../../util/showNotif";

import Checkout from "../checkout/checkout";
import ProductSection from "./ProductSection";

import $ from "jquery"
import "./product.css"
import CashApp from '../checkout/cashapp';

function ProductPage()
{
    const [product, setProduct] = useState({})
    const [selected, setSelected] = useState("product-details-o")
    const [orderAmount, setOrderAmount] = useState(1)
    const [orderId, setOrderId] = useState("")

    useEffect(() => {
        const productNameId = window.location.pathname.split("/")[2]

        let shopProduct = ShopProducts.get(productNameId)
        if(shopProduct)
        {
            setOrderAmount(shopProduct.minimum)
            if(productNameId === "fresh-fa"){
                const freshFeatures = document.getElementsByClassName("fresh-features")
                for(let i = 0; i < freshFeatures.length; i++)
                {
                    freshFeatures[i].style.display = "block"
                }
            }else if(productNameId.includes("-fa")){
                const faFeatures = document.getElementsByClassName("fa-features")
                for(let i = 0; i < faFeatures.length; i++)
                {
                    faFeatures[i].style.display = "block"
                }
            }else if(productNameId.includes("nfa")){
                const nfaFeatures = document.getElementsByClassName("nfa-features")
                for(let i = 0; i < nfaFeatures.length; i++)
                {
                    nfaFeatures[i].style.display = "block"
                }
            }else if(productNameId.includes("vpn")){
                const vpnFeatures = document.getElementsByClassName("vpn-features")
                for(let i = 0; i < vpnFeatures.length; i++)
                {
                    vpnFeatures[i].style.display = "block"
                }
            }else if(productNameId === "14d-gamepass-promo"){
                const gamepassFeatures = document.getElementsByClassName("fourteen-d-gamepass-features")
                for(let i = 0; i < gamepassFeatures.length; i++)
                {
                    gamepassFeatures[i].style.display = "block"
                }
            }else if(productNameId === "skyblock-coins"){
                const skyblockCoinFeatures = document.getElementsByClassName("skyblock-coins-features")
                for(let i = 0; i < skyblockCoinFeatures.length; i++)
                {
                    skyblockCoinFeatures[i].style.display = "block"
                }
            }else if(productNameId === "nitro-three-month"){
                const nitroFeatures = document.getElementsByClassName("three-month-nitro-features")
                for(let i = 0; i < nitroFeatures.length; i++)
                {
                    nitroFeatures[i].style.display = "block"
                }
            }else if(productNameId === "nitro-1-month"){
                const oneMonthFeatures = document.getElementsByClassName("one-month-features")
                for(let i = 0; i < oneMonthFeatures.length; i++)
                {
                    oneMonthFeatures[i].style.display = "block"
                }
            }else if(productNameId === "3month-boost" || productNameId === "1month-boost"){
                const boostFeatures = document.getElementsByClassName("boost-features")
                for(let i = 0; i < boostFeatures.length; i++)
                {
                    boostFeatures[i].style.display = "block"
                }
            }else if(productNameId === "nfa-unbanned-cookies"){
                const cookieNfaFeature = document.getElementsByClassName("cookie-features")
                for(let i = 0; i < cookieNfaFeature.length; i++)
                {
                    cookieNfaFeature[i].style.display = "block"
                }
            }else if(productNameId === "xbox-gamepass-accounts"){
                const xboxGamepassFeatures = document.getElementsByClassName("xbox-gamepass-accounts-features")
                for(let i = 0; i < xboxGamepassFeatures.length; i++)
                {
                    xboxGamepassFeatures[i].style.display = "block"
                }
            }
        }

        setProduct(shopProduct)                
    }, [])

    const changeQuantity = (e) => {
        let oAmount = parseInt(orderAmount);
        if (e.target.id === "i-quantity") {
            if(product.quantity > oAmount)
                oAmount += 1
        }else {
            if(product.minimum < oAmount)
                oAmount -= 1
        }
        setOrderAmount(oAmount)
    }

    const openModal = () => 
        {
        if(product.quantity <= 0)
        {
            addNotif("Not enough stock", "error", 3000)
        }else{
            if(product.minimum <= orderAmount)
            {
                if(product.productNameId === "nfa-unbanned-cookies")
                {
                    addNotif("Only the owner can give the product. Join the discord and make sure the owner is online before buying this product!", "warning", 7000)
                }
                document.querySelector("#navbar-cont").style.zIndex = 0;
                document.querySelector("#s-c-purchase-modal-cont").style.display = "flex"
            }else{
                addNotif(`You must purchase the minimum amount which is ${product.minimum}`, "error", 3000)
            }
        }
    }

    const handleClick = (boom) => {
        $(`#${selected}`).removeClass("selected")
        if (selected === "product-details-o"){
            $(`#product-details`).addClass("hidden")
        }else{
            $(`#warranty`).addClass("hidden")
        }

        $(`#${boom}`).addClass("selected")
        if (boom === "product-details-o"){
            $(`#product-details`).removeClass("hidden")
        }else{
            $(`#warranty`).removeClass("hidden")
        }
        setSelected(boom)
    }

    const onChange = (e) => {
        setOrderAmount(e.target.value)
    }

    return (
        <div>
            <Helmet>
                <title>{`${product.title}`} | Cheap Minecraft Microsoft Alts</title>
                <meta name="description" content={`Shop and purchase for your ${product.title} from us. Cheapest Prices | Lifetime Warranty | Permanent Java + Bedrock Minecraft.`} />
            </Helmet>
            <CashApp orderId={orderId} price={product.price * orderAmount}/>
            <div className='product-page-cont'>
                <div className="p-p-main">
                    <div>
                        <img src={product.imageUrl} className="product-image" alt={product.description}/>
                    </div>
                    <div className="p-p-m-info">
                        <div className="p-p-m-i-cont">
                            <h1 className="p-p-m-header">{product.title}</h1>
                            <ol className="p-p-m-bonus">
                                <li id="permanent-feature" className="fresh-features fa-features">Permanent</li>
                                <li id="permanent-feature" className="vpn-features">Year Warranty</li>
                                <li className="cookie-features">24 Warranty</li>
                                <li id="instant-delivery-feature" className="fresh-features fa-features vpn-features nfa-features fourteen-d-gamepass-features three-month-nitro-features">Instant Delivery</li>
                                <li id="lifetime-warranty-feature" className="fresh-features fa-features three-month-nitro-features">Lifetime Warranty</li>
                                <li id="full-access-feature" className="fresh-features fa-features">Full Access</li>
                            </ol>
                            <article className="p-p-m-description">{product.enhancedDescription}</article>
                        </div>
                        {/* <div className="full-access-addons">                            
                            <div className="fa-addons-options-cont">
                                <div className="fa-addons-option">
                                    <p className="fa-addon-option-title">Prename</p>
                                    <p className="fa-addon-option-price">+ $2.00</p>
                                </div>
                                <div className="fa-addons-option">
                                    <p className="fa-addon-option-title">Migrator Cape</p>
                                    <p className="fa-addon-option-price">+ $2.00</p>
                                </div>
                                <div className="fa-addons-option">
                                    <p className="fa-addon-option-title">Vanilla Cape</p>
                                    <p className="fa-addon-option-price">+ $4.00</p>
                                </div>
                            </div>
                        </div> */}
                        <div className="s-c-p-quantity-setter">
                            <button type="button" className="s-c-p-q-b" id="d-quantity" onClick={changeQuantity}>-</button>
                            <input className="s-c-p-quantity-i" placeholder="1" onChange={onChange} value={orderAmount} type="number"/>
                            <button type="button" className="s-c-p-q-b" id="i-quantity" onClick={changeQuantity}>+</button>
                        </div>

                        <div className="button-div">
                            <button className="purchase" onClick={openModal}>Purchase - ${(product.price * orderAmount).toFixed(2)}</button>
                        </div>
                    </div>
                </div>
                <div className="p-p-extra">
                    <nav className="p-p-nav">
                        <div id="product-details-o" className="p-p-nav-item selected" onClick={event => handleClick("product-details-o")}>Product Details</div>
                        <div id="warranty-o" className="p-p-nav-item" onClick={event => handleClick("warranty-o")}>Warranty</div>
                    </nav>
                    <div id="product-details">
                        <div className="p-p-e-item fresh-features fa-features">
                            <p>Upon purchase, all necessary details and instructions to log into the email and Minecraft account will be sent to your email. You will also receive a complete step by step guide on how to securely change the email to your personal email.</p>
                            <h3>More About This Product</h3>
                            <ol className="p-p-e-more">
                                <li>Java + Bedrock Version</li>
                                <li>Permanent Access</li>
                                <li>100% Customizable</li>
                                <li>Instant Email Delivery</li>
                                <li className="fresh-features fa-features">Lifetime Warranty</li>
                                <li>24/7 Customer Service</li>
                            </ol>
                        </div>
                        <div className="p-p-e-item xbox-gamepass-accounts-features">
                            <p>Upon purchase, you will be redirected to the order link where you will view your product. They will be email:pass and able to log into anywhere. You must set the name of the accounts before playing on some clients.</p>
                            <h3>More About This Product</h3>
                            <ol className="p-p-e-more">
                                <li>Full Access</li>
                                <li>7 - 14 Day Account Age</li>
                                <li>Instant Email Delivery</li>
                                <li>24/7 Customer Service</li>
                            </ol>
                        </div>
                        <div className="p-p-e-item fourteen-d-gamepass-features">
                            <p>Upon purchase, you will be redirected to the order link where you will view your product. These are not accounts, but promos. You will need to activate them with a payment method.</p>
                            <h3>More About This Product</h3>
                            <ol className="p-p-e-more">
                                <li>These are promos to activate 14 day account</li>
                                <li>Debit/Credit Card needed to activate these promos</li>
                                <li>Need USA VPN to activate</li>
                                <li>Instant Email Delivery</li>
                                <li>24/7 Customer Service</li>
                            </ol>
                        </div>
                        <div className="p-p-e-item nfa-features">
                            <p>After purchase, you will need to join the discord server to claim the product. You should make sure the server owner is online before buying. You will also receive a step by step guide on how to log into these accounts.</p>
                            <h3>More About This Product</h3>
                            <ol className="p-p-e-more">
                                <li>Temporary Access</li>
                                <li>Unbanned from Hypixel</li>
                                <li>Instant Email Delivery</li>
                                <li>24/7 Customer Service</li>
                            </ol>
                        </div>
                        <div className="p-p-e-item one-month-features">
                            <p>After purchase you will receive an email with the product.</p>
                            <h3>More About This Product</h3>
                            <ol className="p-p-e-more">
                                <li>1 Month Nitro Premium</li>
                                <li>2x Server Boost</li>
                                <li>24 hours to claim</li>
                                <li>Instant Email Delivery</li>
                            </ol>
                        </div>
                        <div className="p-p-e-item three-month-nitro-features">
                            <p>For full information about the promo read <a href="https://support.discord.com/hc/en-us/articles/4410647162263-3-months-of-Nitro-with-Xbox-Game-Pass-Ultimate-FAQ">Discord Three Month Promo</a></p>
                            <h3>More About This Product</h3>
                            <ol className="p-p-e-more">
                                <li>3 Month Discord Nitro (Not Basic)</li>
                                <li>2x Three Month Nitro Boost</li>
                                <li>Account must be at least 30 days old</li>
                                <li>Lifetime Warranty</li>
                                <li>Instant Email Delivery</li>
                                <li>24/7 Customer Service</li>
                            </ol>
                        </div>
                        <div className="p-p-e-item vpn-features">
                            <p>Upon purchase, you will instantly be sent credentials and instructions regarding how to log into these accounts. Do NOT change any information on the accounts or you may be locked out which will void you from our warranty.</p>
                            <h3>More About This Product</h3>
                            <ol className="p-p-e-more">
                                <li>Lifetime Access</li>
                                <li>Lifetime Warranty</li>
                                <li>Instant Email Delivery</li>
                                <li>24/7 Customer Service</li>
                            </ol>
                        </div>
                        <div className="p-p-e-item skyblock-coins-features">
                            <p>After purchase you will be prompted to join the discord server and provide your in game username which the coins will be transferred to.</p>
                            <h3>More About This Product</h3>
                            <ol className="p-p-e-more">
                                <li>Must be at least level 50 on Skyblock.</li>
                                <li>Not Duped/Fake/Stolen/Macroed coins. 100% Authentic and Legit coins.</li>
                                <li>24/7 Customer Service</li>
                            </ol>
                        </div>
                        <div className="p-p-e-item boost-features">
                            <p>After purchase you can claim these boost at your own time.</p>
                            <h3>More About This Product</h3>
                            <ol className="p-p-e-more">
                                <li>Fast Delivery</li>
                                <li>24/7 Customer Service</li>
                            </ol>
                        </div>
                    </div>
                    <div id="warranty" className="hidden">
                        <div className="p-p-e-item">
                            <p>This warranty is for this product and may vary for all other products.</p>
                            <h3>Warranty</h3>
                            <ol className="p-p-e-more fourteen-d-gamepass-features">
                                <li>14 days to check and report if product doesn't work.</li>
                            </ol>
                            <ol className="p-p-e-more xbox-gamepass-accounts-features">
                                <li>7 day warranty.</li>
                            </ol>
                            <ol className="p-p-e-more fa-features fresh-features">
                                <li>24 hours to change the email for the product to your own and to report any issues with doing so.</li>
                                <li>After changing the email you have a lifetime warranty on the product.</li>
                            </ol>
                            <ol className="p-p-e-more one-month-features">
                                <li>24 hours to claim</li>
                                <li>1 month warranty after claiming</li>
                            </ol>
                            <ol className="p-p-e-more boost-features">                                
                                <li>30 day warranty.</li>
                                <li>If the accounts are kicked due to anti raid on your server, your warranty will be voided. Disable all antispam/raid before purchasing.</li>
                            </ol>
                            <ol className="p-p-e-more three-month-nitro-features">
                                <li>24 hours to claim the promo</li>
                                <li>1 month warranty after promo is claimed</li>
                            </ol>
                            <ol className="p-p-e-more skyblock-coins-features">
                                <li>There is no warranty due to the nature of Hypixel's policy with irl trading.</li>
                                <li>Having high level skyblock account significantly decreased the chance of being banned.</li>
                            </ol>
                            <ol className="p-p-e-more nfa-features">
                                <li>24 hours</li>
                            </ol>
                        </div>
                    </div>
                </div>

                <Checkout product={product} orderAmount={orderAmount} setOrderId={setOrderId} checkoutType="Product"/>
            </div>
            <ProductSection/>
        </div>
    );
}

export default ProductPage;