export const hideElementById = (id) =>
{
    document.getElementById(id).style.display = "none";
}

export const showElementById = (id) =>
{
    document.getElementById(id).style.display = "block";
}

export const hideElementsByClassName = (className) =>
{
    const elements = document.getElementsByClassName(className)

    for(let i = 0; i < elements.length; i++)
    {
        elements[i].style.display = "none"
    }
}

export const toggleElementById = (id) =>
{
    if(document.getElementById(id).style.display == "none")
    {
        document.getElementById(id).style.display = "block"
    }else{
        document.getElementById(id).style.display = "none"
    }
}