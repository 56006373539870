import "./tos.css"

function TOS()
{
    return (
        <div className="tos">
            <h1 className="tos-header">
                Terms Of Services
            </h1>
            <p className="tos-description">
            <i>These terms and conditions outline the rules and regulations for the use of AltsGreat's Website. Breaking any of our Terms of Services will result in a removal of your warranty, revocation or halt of any services/products and a blacklist and/or ban from all services provided by AltsGreat.</i>
            </p>
            <h2>1. Acceptance of Terms</h2>
            <p className="terms">
            The terms "we", "us" and "our" refer to ALTSGREAT, the account creators or the suppliers. We offer this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here. By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service. Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes. Some payment methods may have fees. All customer account conditions are subject to change at any time without notice.
            </p>
            <h2>2. Refund and Reimbursement Policy</h2>
            <p className="terms">
            AltsGreat only offers refunds in the case that the product/service can not be delivered to the client/customer. This will require contacting our support team which will investigate the issue throughly. For reimbursements, the client/customer must contact us through discord and provide accurate information about their inquiry. Warranties apply only for faulty products. For reimbursements, Providing any false information or hiding any information related to their inquiry will be counted as a violation of our Terms of Service.
            </p>
            <h2>3. Warranties</h2>
            <p className="terms">
            All warranties may be different depending on the product/service which is stated along with the product/service. In the case that any of our terms of services are broken, we reserve the right to withdraw your warranty without any necessary explanation. It is the customers'/clients' responsibility to know the specifications of their warranty.
            </p>
            <h2>4. Pricing and Payment</h2>
            <p className="terms">
            Prices for our product and services are listed on our website and may change. We accept various payment methods as listed on our website. By submitting a payment, you confirm that you are authorized to use the payment method and agree with all of our terms of services.
            </p>
            <h2>5. Communication</h2>
            <p className="terms">
            Any explicit or, otherwise infamatory language, slander, or defamation will result in a block of any and all services including access to our web services, Discord, and Email. Please use common sense when talking to the operators of AltsGreat and reach out to the proper channels in case of any issues. If accessing our Discord server, you must abide by all rules/regulations provided which can change at any time. Breaking any rules and regulations will be counted as a violation of our terms and services.
            </p>
            <h2>6. Instructions</h2>
            <p className="terms">
            Every product provided by AltsGreat is guaranteed to come with a set of personalized instructions that you must follow in order to utilize your product. If for any reason you do not follow the instructions properly and your product is malfunctioning, which is including but not limited, to: a video guide, demo product/service; we are not obligated to provide you any warranty. If you have any questions or are confused about our instructions, please contact us through our Discord server.
            </p>
            <h2>7. Claims and Guarantees</h2>
            <p className="terms">
            Any claims made by AltsGreat or its staff are not guaranteed accuracy, unless explicitly stated otherwise by our Terms of Service or the sole owners of the website, however are generally accurate. AltsGreat does not guarantee access to any of it's services, for any reason it may be, such as but not limited: Loss of Data, Downtime, and more. AltsGreat suggests keeping any important data such as product information or instructions from our website locally available, in accordance to our License.
            </p>
            <h2>8. Privacy</h2>
            <p className="terms">
            AltsGreat will try it's best to keep any customer or user information private. Any personally identifiable information is only accessable to the owner(s) of AltsGreat and if any breaches are noticed, we will notify our customers of such accordingly. We do not guarantee your privacy, however as stated, will do our best to keep your information private and secure. AltsGreat utilizies various techniques to secure any information that is private. We do not directly collect personally identifable information such as names or addresses. We may collect IP Addresses which is only utilized for fraud prevention, and email addressesfor product delivery purposed.
            </p>
            <h2>9. Fraud</h2>
            <p className="terms">
            AltsGreat does not tolerate any fraud or attempt to defraud any of its services, customers, staff, or business. Doing so will result in consequences such as but not limited to: Legal action, permanent suspension from all AltsGreat services, and anything else that we may deem necessary. The term "fraud" includes but is not limited to: Credit/Debit Card fraud, Chargeback fraud, Dispute fraud, Identity fraud, Unauthorized Banking Fraud, Unauthorized Purchases, Phishing, and Social Engineering.
            </p>
            <h2>10. Accusations/Threats</h2>
            <p className="terms">
            Any false accusations made towards AltGreat will result in an immediately removal of your warranty and counted as breaking our terms of services. If you are unsure or need assistance then contact our support and give a chance to resolve the issue. Any threats made towards AltGreat will count as breaking our terms of services. 
            </p>
            <h2>11. Abuse</h2>
            <p className="terms">
            AltsGreat reserves the right to define abuse of it's policy for any reason, however, the most general reasons for abuse of policy may include but is not limited to: Denial of Service, Spam, Malware, attempts to hack or gain access to any of its services which is otherwise prohibitied, or making any false claims to our Support. Any abuse will be dealt with accordingly, but the most general result to the individual behind the abuse is subject to a bar from our services and possible legal action according to local laws.
            </p>
        </div>
    )
}

export default TOS;