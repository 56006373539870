import './store.css';

import { useEffect } from "react";
import { Helmet } from 'react-helmet';

import SlideShowCont from "./slideshow/SlideShowCont";
import ProductSection from "./product/ProductSection";

import { setCookie } from '../util/cookies';
import { Route, Routes } from 'react-router-dom';

import ProductPage from './product/ProductPage';
import StatsFinder from './statfinder/statfinder';
import InvoicePage from './invoicepage/invoicePage';

function Store() {
  function checkCookie(cookieName) {
    return document.cookie.split(';').some(cookie => {
      return cookie.trim().startsWith(cookieName + '=');
    });
  }

  async function onload() {
    let i = 0;

    let intervals = setInterval(() => {

      if (document.getElementById("slideshow-one")) {
        let slideshowids = ["slideshow-one", "slideshow-two", "slideshow-three", "slideshow-four"]

        document.getElementById(slideshowids[i % slideshowids.length]).style.display = "none"
        document.getElementById(slideshowids[(i + 1) % slideshowids.length]).style.display = "flex"
        document.getElementById(slideshowids[(i + 2) % slideshowids.length]).style.display = "none"
        document.getElementById(slideshowids[(i + 3) % slideshowids.length]).style.display = "none"
        if (i === slideshowids.length - 1) {
          i = 0;
        } else {
          i++;
        }
      } else {
        clearInterval(intervals)
      }
    }, 10000)

    let referrer;

    // Check who referred the user to the store
    if (window.localStorage.getItem("visited")) {
      referrer = "default"
      setCookie("referrer", referrer, 14);
      window.localStorage.removeItem("visited")

      // await updateReferences({
      //   referenceId: referrer
      // })
    } else if (!checkCookie("referrer")) {
      if (window.location.pathname && window.location.pathname.includes("r=")) {
        referrer = window.location.pathname.split("r=")[1]
      } else {
        referrer = "default"
      }
      setCookie("referrer", referrer, 14);

      // await updateReferences({
      //   referenceId: referrer
      // })
    }
  }

  useEffect(() => {
    onload()
  }, [])


  return (
    <div className="store-cont">
      <Routes>
        <Route path="/*" element={<div>
          <Helmet>
            <title>Buy Minecraft Accounts | Cheap Alt Shops | Full Access Accounts</title>
          </Helmet>
          <SlideShowCont />
          <div className="pm-message">
            <i>Join the Discord server to pay with Paypal, Venmo, Zelle or LTC</i>
          </div>
          <ProductSection />
        </div>}/>
        <Route path="/products/*" element={<ProductPage />} />
        <Route path="/statsfinder" element={<StatsFinder />} />
        <Route path="/invoice/*" element={<InvoicePage />} />
      </Routes>
    </div>
  );
}

export default Store;
