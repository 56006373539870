const axios = require('axios');

export const convertUsernameToUUID = async (username) =>
{
    try{
        const response = await axios.get(`https://api.mojang.com/users/profiles/minecraft/${username}`)
        return response.data.id
    }catch(err){
        console.log(err)
        console.log("[ERROR]: Getting profile for " + username)
        return ;
    }
}

async function convertUUIDToUsername(uuid)
{
    const response = await axios.get(`https://sessionserver.mojang.com/session/minecraft/profile/${uuid}`)
    console.log(response.data.name)
    return response.data.name
}