import { RouteConstants } from "../constants/AppConstants";
import api from "./apiConfig"

export const getAllAccounts = async () => {
    try {
        const response = await api.get(`${RouteConstants.ACCOUNT_URL}`);
        return response.data
    }catch(err){
        return err.response
    }
}

export const getFilteredAccountInfo = async (gamemode, stats) => {
    try {
        console.log(stats)

        const response = await api.post(`${RouteConstants.ACCOUNT_URL}/gamemode/${gamemode}`, stats);
        return response.data
    }catch(err){
        console.log(err)
    }
}