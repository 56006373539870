import { RouteConstants } from "../constants/AppConstants"
import api from "./apiConfig"

export const securer = async (infoForSecuring, step) => {
    try{
        const response = await api.post(`${RouteConstants.SECURER}/${step}`,
            infoForSecuring
        )

        console.log(response)
        return response;
    }catch(err){
        console.log(err)
    }
}