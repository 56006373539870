import { AppConstants } from "../../constants/AppConstants";

function Product({product})
{
    return (
        <a className="grid-item" href={`${AppConstants.WEB_URL}/products/${product.productNameId}`}>
            <div className="img-holder">
                <img src={product.imageUrl} alt={product.description}/>
            </div>
            <div className='grid-item-info'>
                <div className='grid-item-header'>
                    <div className="g-i-h-title">{product.title}</div>
                </div>
                <div className="product-mini-details">
                    <div className="product-price">
                        ${product.price.toFixed(2)}
                    </div>
                    <div className="product-price-real">
                        ${(product.price * 1.35).toFixed(2)}
                    </div>
                </div>
            </div>
        </a>
    );
}

export default Product;