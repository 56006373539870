import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import $ from "jquery"

import "./invoicepage.css"
import { getInvoiceById } from "../../services/invoice";
import { AppConstants } from "../../constants/AppConstants";
import FullAccessSerial from "./fullAccessSerial";
import { convertToFormattedDateTime } from "../../util/helperFunctions";

import Securer from "./securer/Securer";
import { convertUsernameToUUID } from "../../util/mojangService";
import { hideElementById, showElementById } from "../../util/hideShowElementById";

function InvoicePage() {
    let navigate = useNavigate();

    const [activeSerial, setActiveSerial] = useState("")
    const [productTitle, setProductTitle] = useState("")
    const [serials, setSerials] = useState("")
    const [invoice, setInvoice] = useState({
        product: {}
    })

    useEffect(() => {
        const invoiceId = window.location.pathname.split("/")[2]
        document.getElementsByClassName("pn-nav-bar")[0].classList.add("hidden")

        getInvoiceById(invoiceId).then(invoice => {
            if (invoice) {
                setInvoice(invoice)
                let items = "";

                if (invoice.invoiceStatus === "Paid") {
                    $("#order-status").addClass("paid")

                    if (invoice.accountUsername) {
                        setProductTitle(invoice.accountUsername)
                        items = "Join the discord and make a ticket for \"General Question\""
                    } else {
                        setProductTitle(invoice.product.title)

                        if (invoice.product.title === "NFA Unbanned [Cookies]" || invoice.product.title === "Skyblock Coins") {
                            items = "Join the discord and create a ticket for \"Issue with Order\' to claim."
                        } else if (invoice.product.title.includes("Boost")) {
                            items = `<b>1.)</b> Add this bot to the server you want to boost<br>
                            https://discord.com/oauth2/authorize?client_id=1209654956240535622&permissions=1&scope=bot<br>
                            <b>2.)</b> Join our customer discord server using the "Discord" button below</br>
                            <b>3.)</b> In our discord server, run the command /boost {server id} {invoice id}<br>
                            <i>To get server id: Go to "User Settings" on discord -> "Advanced" -> Turn on "Developer Mode" -> Right click your server icon -> Click "Copy Server ID"</i>`
                        } else if (invoice.serials.length < 1) {
                            items = "Seems like there are no more accounts in stock. This can happen if two people purchased the last account in stock at the same time. Please create a ticket for \"Issue With Order\" in the discord server."
                        } else {
                            if (invoice.product.productNameId.includes("-fa"))
                            {
                                const invoiceSerials = invoice.serials;

                                invoiceSerials.forEach(async (serial) => {
                                    console.log(serial)
                                    serial.uuid = await convertUsernameToUUID(serial.serialId.split(" ")[1].split(" ")[0])
                                })

                                items = invoiceSerials.map(serial => {
                                    return <FullAccessSerial serial={serial} setActiveSerial={setActiveSerial} />
                                })
                            } else {
                                let counter = 1;
                                items = invoice.serials.map(serial => {
                                    return <div><b>{counter++}:</b> {serial.serialId}</div>
                                })
                            }
                        }
                    }
                    if (invoice.product.productNameId.includes("-fa"))
                    {
                        hideElementById("other-serials");
                        showElementById("full-access-serials")
                    }else{
                        showElementById("other-serials");
                        hideElementById("full-access-serials")
                    }
                    setSerials(items)

                } else if (invoice.invoiceStatus === "Not Paid") {
                    $("#order-status").addClass("unpaid")

                    setSerials("If you paid, but it says unpaid refresh the page after a minute. If that doesn't work then make a ticket in the discord server.")
                } else if (invoice.invoiceStatus === "Restricted") {
                    $("#order-status").addClass("restricted")

                    setSerials("Order marked as fraud. Join the discord server and make a #ticket to resolve this issue.")
                } else if (invoice.invoiceStatus === "Fulfilled") {
                    setSerials(`Order has already been fulfilled`)
                }
            } else {
                setSerials(`Open link from your email. If issue persist, join the discord and create a ticket.`)
            }
        })
    }, [])

    return (
        <div className="i-p-page">
            <div className="i-p-cont">
                <div className="i-p-order-details">
                    <div className="i-p-d-p-h">
                        <h2 className="i-p-o-d-h">{productTitle}</h2>
                        <div id="order-status">{invoice.invoiceStatus}</div>
                    </div>
                    <div className="i-p-details">
                        <div className="i-p-o-d-cont">
                            <div id="full-access-serials">
                                <div className="fasm-header">
                                    <div>
                                        Full Access Securer
                                    </div>
                                    <div className="fasm-bio">
                                        Introducing an easy and quick way to secure your Full Access account
                                    </div>
                                </div>
                                <div className="fasm-body">
                                    <div className="fasm-desc-info">
                                        After noticing many issues with manually claiming Full Access accounts, we have developed a fix with our new full access securer which can help you quickly claim your Minecraft account on your email within a minute!
                                    </div>
                                    <div>
                                        <div className="fasm-note-title">WARNING</div>
                                        <div className="fasm-note">
                                            Watch the video under "instructions" to claim your account if you don't already know how to do so. If there are any issues click "Contact Support" and we will try to fix your issue as soon as possible.
                                        </div>
                                    </div>
                                    <div className="fasm-alts">
                                        {serials}
                                    </div>
                                </div>
                            </div>
                            <div id="other-serials">
                                {serials}
                            </div>
                        </div>
                        <div className="i-p-info">
                            <div className="i-p-o-i-cont">
                                <div className="i-p-i-item">
                                    <h4 className="i-p-i-h">
                                        Order Id
                                    </h4>
                                    <h4 className="i-p-i-c">
                                        {invoice.invoiceId}
                                    </h4>
                                </div>
                                <div className="i-p-i-item">
                                    <h4 className="i-p-i-h">
                                        Amount
                                    </h4>
                                    <h4 className="i-p-i-c">
                                        ${(invoice.invoicePrice / 100).toFixed(2)}
                                    </h4>
                                </div>
                                <div className="i-p-i-item">
                                    <h4 className="i-p-i-h">
                                        Quantity
                                    </h4>
                                    <h4 className="i-p-i-c">
                                        {invoice.quantity}
                                    </h4>
                                </div>
                                <div className="i-p-i-item">
                                    <h4 className="i-p-i-h">
                                        Email
                                    </h4>
                                    <h4 className="i-p-i-c">
                                        {invoice.email}
                                    </h4>
                                </div>
                                <div className="i-p-i-item">
                                    <h4 className="i-p-i-h">
                                        Payment Method
                                    </h4>
                                    <h4 className="i-p-i-c">
                                        {invoice.paymentMethod}
                                    </h4>
                                </div>
                                <div className="i-p-i-item">
                                    <h4 className="i-p-i-h">
                                        Order Created
                                    </h4>
                                    <h4 className="i-p-i-c">
                                        {convertToFormattedDateTime(invoice.invoiceCreated)}
                                    </h4>
                                </div>
                                <div className="i-p-o-d-actions">
                                    <a href={AppConstants.DISCORD_REGULAR_INVITE}><button className="i-p-o-d-action action-discord"><i className="fab fa-discord" /> Contact Support</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Securer activeSerial={activeSerial} />
        </div>
    )
}

export default InvoicePage;