import "./cashapp.css"

import CashAppQrCode from "../../images/cashappqrcode.png"
import { hideElementById, showElementById } from "../../util/hideShowElementById"
import { cashAppCheck, getOrderStatusById } from "../../services/invoice"
import { useState } from "react"

function CashApp({orderId, price})
{
    const [ cashAppPaymentId, setCashAppPaymentId ] = useState("")

    let checkingStarted;
    let isChecking = true;
    let intervalId;

    const checkOrderStatus = async () => {
        const response = await getOrderStatusById(orderId);

        const orderStatus = response.data;

        if(orderStatus === "Paid")
        {
            isChecking = false;
            showElementById("cashapp-pay-success");
            hideElementById("cashapp-receipt-modal");
            clearInterval(intervalId);
        }
    }

    const openPaymentInfoModal = () => {
        showElementById("cashapp-pay-modal")
        hideElementById("cashapp-receipt-modal")
    }

    const openReceiptModal = () => {
        hideElementById("cashapp-pay-modal")
        showElementById("cashapp-receipt-modal")

        if(!checkingStarted)
        {
            if (isChecking) 
            {
                intervalId = setInterval(() => 
                {
                    checkOrderStatus()
                }, 5000)
            }
            checkingStarted = true;
        }
    }

    const cancelCashApp = () => {
        hideElementById("cashapp-popup")
        showElementById("cashapp-pay-modal")
        hideElementById("cashapp-receipt-modal")
        hideElementById("cashapp-pay-success");
        clearInterval(intervalId);
        document.getElementById("cashapp-receipt-modal").reset();
    }

    const submitCashAppLink = async (e) => {
        e.preventDefault()
        const response = await cashAppCheck(orderId, cashAppPaymentId)

        if(response.status !== 200)
        {
            const response_error = response.data;

            showElementById(response_error)
        }else{
            window.location.href = response.data;
        }
    }

    const checkInput = (e) => {
        const input = e.target.value
        const regex = /^https:\/\/cash\.app\/payments\/[a-zA-Z0-9]+\/receipt\?utm_source=activity-item$/;

        setCashAppPaymentId()
        if (regex.test(input)) {
            showElementById("ca-input-verifier");
            hideElementById("EXPIRED_LINK")
            document.getElementById("receipt-input").classList.remove("ca-payment-input-error")
            document.getElementById("receipt-input").classList.add("ca-payment-input-success")
            setCashAppPaymentId(input.split("payments/")[1].split("/")[0])
        } else {
            hideElementById("ca-input-verifier");
            showElementById("EXPIRED_LINK")
            document.getElementById("receipt-input").classList.add("ca-payment-input-error")
            document.getElementById("receipt-input").classList.remove("ca-payment-input-success")
        }
    }

    return (
        <div className="cashapp-cont" id="cashapp-popup">
            <div id="cashapp-pay-modal" className="cashapp-pay-modal">
                <div className="cashapp-pay-header">
                    <div className="cashapp-btn-order-id"><b>Order #:</b> {orderId}</div>
                </div>
                <div className="cashapp-pay-pm-info">
                    <div className="ca-img-cont">
                        <img src={CashAppQrCode} alt="caashapp qr code" className="ca-img"/>
                    </div>
                    <div className="ca-scan-msg">
                        Scan to Pay
                    </div>
                    <div className="cashapp-details">
                        <div className="cashapp-username">
                            Great
                        </div>
                        <div className="cashapp-tag">
                            $altsgreat                        
                        </div>
                    </div>
                    <div className="cashapp-payment-details">
                        <h6 className="ca-payment-header">Amount to send</h6>
                        <div className="ca-payment-input-cont">
                            <div className="ca-dollar-sign">
                                $
                            </div>
                            <input className="ca-payment-input" value={price.toFixed(2)}/>
                            <div className="ca-dollar-currency">
                                USD
                            </div>
                        </div>
                        <p className="ca-amount-warning">Send the exact amount or it will not be detected.</p>
                    </div>
                </div>
                <div className="cashapp-pay-footer">
                    <div className="cashapp-pay-waiting">
                        <div className="">Waiting for Payment...</div>
                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </div>
                    <div className="ca-done-paying-msg">After paying, click "Payment Sent" below.</div>
                    <div className="cashapp-pay-actions">
                        <div className="cashapp-btn-cancel" onClick={cancelCashApp}>Cancel</div>
                        <div className="cashapp-btn-paid" onClick={openReceiptModal}>Payment Sent</div>
                    </div>
                </div>
            </div>
            <form className="cashapp-pay-modal" id="cashapp-receipt-modal" onSubmit={submitCashAppLink}>
                <div className="cashapp-pay-header">
                    <div className="cashapp-btn-order-id"><b>Order #:</b> {orderId}</div>
                </div>
                <div className="cashapp-receipt-info">
                    <div className="ca-return-to-payment" onClick={openPaymentInfoModal}>
                        <i class="fa fa-arrow-left" aria-hidden="true"/> Return to payment
                    </div>
                    <div className="cashapp-receipt-details">
                        <h6 className="ca-payment-header">CashApp Web Receipt Url</h6>
                        <div id="receipt-input" className="ca-payment-input-cont">
                            <input onChange={checkInput} className="ca-payment-input" placeholder="https://cash.app/payments/7tt92p3yl439j2f7mansdgubo/receipt?utm_source=activity-item"/>
                            <div id="ca-input-verifier">
                                <i class="fas fa-check"/>
                            </div>
                        </div>
                        <p className="ca-amount-warning">Copy and paste the entire link</p>
                        <p id="UNDER_PAID" className="ca-err-msg">Insufficient funds sent</p>
                        <p id="INVALID_LINK" className="ca-err-msg">Invalid link</p>
                        <p id="EXPIRED_LINK" className="ca-err-msg">Expired link</p>
                        <p id="PAYMENT_INCOMPLETE" className="ca-err-msg">Payment was not completed.</p>
                        <p id="EXISTING_LINK" className="ca-err-msg">Payment used for another order.</p>
                        <p id="WRONG_RECEIPIENT" className="ca-err-msg">That payment was not sent to $altsgreat.</p>
                        <p id="INTERNAL_SERVER_ERROR" className="ca-err-msg">Unknown Error</p>
                    </div>
                    <div className="cashapp-receipt-details">
                        <h6 className="ca-payment-header">How to get receipt URL.</h6>
                        <ol className="ca-receipt-steps">
                            <li>- Click on the CashApp Payment</li>
                            <li>- Click "Web Receipt"</li>
                            <li>- Copy and paste the link</li>
                        </ol>
                    </div>
                </div>
                <div className="cashapp-pay-footer">
                    <div className="cashapp-pay-waiting">
                        <div className="">Waiting for Receipt Link...</div>
                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </div>
                    <div className="ca-done-paying-msg">Enter receipt link and click "Submit Link" below</div>
                    <div className="cashapp-pay-actions">
                        <div className="cashapp-btn-cancel" onClick={cancelCashApp}>Cancel</div>
                        <input type="submit" className="cashapp-btn-paid" value="Submit Link"/>
                    </div>
                </div>
            </form>
            <div id="cashapp-pay-success" className="cashapp-pay-modal">
                <div className="cashapp-pay-header">
                    <div className="cashapp-btn-order-id"><b>Order #:</b> {orderId}</div>
                </div>
                <div className="cashapp-pay-pm-info">
                    <h4  className="ca-success-header">Successfully Paid</h4>
                    <div class="success-animation">
                        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                    </div>
                    <h4  className="ca-success-footer">Order Received! We will email you shortly. Please check your spam if it is not in your inbox.</h4>
                </div>
                <div className="cashapp-pay-footer">
                    <div className="cashapp-pay-actions">
                        <div className="cashapp-btn-cancel" onClick={cancelCashApp}>Close</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CashApp;