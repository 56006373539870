import "./privacy.css"

function Privacy()
{
    return (
        <div className="tos">
            <h1 className="tos-header">
                Privacy Policy
            </h1>
            <p className="tos-description">
            <i>These privacy outlines the how user information is processed on our end and the rules and regulation for the use of AltsGreat's Website.</i>
            </p>
            <h2>Your privacy is critically important to us.</h2>
            <p className="terms">
            It is AltsGreat’s policy to respect your privacy regarding any information we may collect while operating our website. This Privacy Policy applies to https://www.altsgreat.com (hereinafter, "us", "we", or "https://www.altsgreat.com"). We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website. We have adopted this privacy policy ("Privacy Policy") to explain what information may be collected on our Website, how we use this information, and under what circumstances we may disclose the information to third parties. This Privacy Policy applies only to information we collect through the Website and does not apply to our collection of information from other sources.

This Privacy Policy, together with the Terms and conditions posted on our Website, set forth the general rules and policies governing your use of our Website. Depending on your activities when visiting our Website, you may be required to agree to additional terms and conditions.
            </p>
            <h2>Website Visitors</h2>
            <p className="terms">
            Like most website operators, AltsGreat collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. AltsGreat’s purpose in collecting non-personally identifying information is to better understand how AltsGreat’s visitors use its website. From time to time, AltsGreat may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.

AltsGreat also collects potentially personally-identifying information like Internet Protocol (IP) addresses for logged in users and for users leaving comments on https://www.altsgreat.com discord posts. AltsGreat only discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below.
            </p>
            <h2>Gathering of Personally-Identifying Information</h2>
            <p className="terms">
            Certain visitors to AltsGreat’s websites choose to interact with AltsGreat in ways that require AltsGreat to gather personally-identifying information. The amount and type of information that AltsGreat gathers depends on the nature of the interaction. For example, we ask visitors who sign up for an account at https://www.altsgreat.com to provide a username and email address.
            </p>
            <h2>Security</h2>
            <p className="terms">
            The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
            </p>
            <h2>Links To External Sites</h2>
            <p className="terms">
            Our Service may contain links to external sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy and terms and conditions of every site you visit.

We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites, products or services.
            </p>
            <h2>Aggregated Statistics</h2>
            <p className="terms">
            AltsGreat may collect statistics about the behavior of visitors to its website. AltsGreat may display this information publicly or provide it to others. However, AltsGreat does not disclose your personally-identifying information.
            </p>
            <h2>Cookies</h2>
            <p className="terms">
            To enrich and perfect your online experience, AltsGreat uses "Cookies", similar technologies and services provided by others to display personalized content, appropriate advertising and store your preferences on your computer.

A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s browser provides to the website each time the visitor returns. AltsGreat uses cookies to help AltsGreat identify and track visitors, their usage of https://www.altsgreat.com, and their website access preferences. AltsGreat visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using AltsGreat’s websites, with the drawback that certain features of AltsGreat’s websites may not function properly without the aid of cookies.

By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and agree to AltsGreat's use of cookies.
            </p>
            <h2>E-commerce</h2>
            <p className="terms">
            Those who engage in transactions with AltsGreat – by purchasing AltsGreat's services or products, are asked to provide additional information, including as necessary the personal and financial information required to process those transactions. In each case, AltsGreat collects such information only insofar as is necessary or appropriate to fulfill the purpose of the visitor’s interaction with AltsGreat. AltsGreat does not disclose personally-identifying information other than as described below. And visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from engaging in certain website-related activities.
            </p>
            <h2>Privacy Policy Changes</h2>
            <p className="terms">
            Although most changes are likely to be minor, AltsGreat may change its Privacy Policy from time to time, and in AltsGreat’s sole discretion. AltsGreat encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.
            </p>
        </div>
    )
}

export default Privacy;