import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { uploadSerialsByProductId } from '../../services/admin';
import { createProduct, updateProduct } from '../../services/product';

function Dashboard()
{
    let navigate = useNavigate();

    const [ formData, setFormData ] = useState({})

    useEffect(() => {
        if(!window.sessionStorage.getItem("Authorization"))
        {
            navigate("/admin")
        }
    })

    const handleSubmit = async (e) => {
        e.preventDefault()
        if(e.target.name === "serials")
        {
            const response = await uploadSerialsByProductId(formData, document.getElementById("productId").value)
            if(response.status === 201)
            {
                document.getElementById("serial-form").reset();
                alert(`Uploaded ${response.data} serials`)
            }else{
                alert("Error")
            }
        }
        else if(e.target.name === "product")
        {   
            const productId = document.getElementById("product-update-id").value
            if(productId)
                await updateProduct(productId, formData)
            else
                await createProduct(formData)
        }
    }

    const handleChange = (e) => {
        let { name, value } = e.target;
        setFormData({
            [name]: value
        })
    }

    return(
        <div className='admin-dashboard'>
            <form id="serial-form" name="serials" className='upload-form' onSubmit={handleSubmit}>
                <h2>Add Serials</h2>
                <textarea name="serialId" className='serial-upload-box' onChange={handleChange}/>
                <input placeholder='Enter Product Id' id="productId"/>
                <input className='upload-serial-input' type='submit' value="Upload Serials"/>
            </form>
            <form name="product" className='upload-form' onSubmit={handleSubmit}>
                <h2>Add Product</h2>
                <input name="title" placeholder='Product Title' onChange={handleChange}/>
                <br/>
                <input name="price" placeholder='Product Price' onChange={handleChange}/>
                <br/>
                <input name="minimum" placeholder='Product Minimum' onChange={handleChange}/>
                <br/>
                <input name="description" placeholder='Product Description' onChange={handleChange}/>
                <br/>
                <input name="instructions" placeholder='Product Instructions' onChange={handleChange}/>
                <br/>
                <input name="productNameId" placeholder='Product Name Id'/>
                <br/>
                <input id="product-update-id" placeholder='Product Id'/>
                <br/>
                <textarea name="enhancedDescription" className='serial-upload-box' onChange={handleChange}/>
                <br/>
                <input type='submit' value="Create Product"/>
            </form>
        </div>
    )
}

export default Dashboard;