import { Route, Routes } from 'react-router-dom';

import TOS from './tos';
import Privacy from '../privacy/privacy';

import "./help.css"
import Navbar from '../navbar/navbar';
import MiniNavbar from '../navbar/miniNavbar';

function Help() {
    return (
        <div className="help-cont">
            <MiniNavbar />
            <Routes>
                <Route path="/terms-of-service" element={<TOS />}/>
                <Route path="/privacy" element={<Privacy />} />
            </Routes>
        </div>
    );
}

export default Help;
