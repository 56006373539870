import "./footer.css"

import logo from '../../images/logo.webp'
import { Link } from "react-router-dom";

function Footer()
{
    return (
        <div className="footer-cont">
            <div className="footer-items">
                <div className="footer-header">
                    <img className="footer-logo" src={logo}/>
                </div>
                <div className="footer-msg">
                    <h3 className="footer-msg-header">Fueled by AltsGreat</h3>
                    <p className="footer-msg-p">AltsGreat is a leader in Minecraft Alt technology, offering top-quality accounts known for their reliability and excellence.</p>
                </div>
                <div className="footer-legal">
                    <h3 className="footer-msg-header">Legal</h3>
                    <Link className="footer-link" to={"/help/terms-of-service"}>Terms of Service</Link>
                    <Link className="footer-link" to={"/help/privacy"}>Privacy</Link>
                </div>
            </div>
        </div>
    );
}

export default Footer