export const AppConstants = {
    BASE_URL: "http://localhost:8080",
    WEB_URL: "http://localhost:3000",
    
    DISCORD_PAYMENT_INVITE: "https://discord.gg/qhdJJVeHTB",
    DISCORD_REGULAR_INVITE: "https://discord.com/invite/wrsrKw3dTv",

    BASE_URL: "https://altsgreat-72x9.onrender.com",
    WEB_URL: "https://altsgreat.com",
}

export const RouteConstants = {
    SECURER: "/securer",
    LOGIN_API_URL: "/user",
    AUTHORITIES_URL: "/authorities",
    PRODUCTS_URL: "/products",
    INVOICES_URL: "/invoices",
    USERS_URL: "/users",
    STRIPE_URL: "/stripe",
    SERIAL_URL: "/serials",
    ACCOUNT_URL: "/accounts",
    REFERENCE_URL: "/references"
}