import axios from "axios";
import { AppConstants, RouteConstants } from "../constants/AppConstants";

const api = axios.create({
  baseURL: AppConstants.BASE_URL
});

/**
 * Intercept request to the server
 */
api.interceptors.request.use(
  request => {
    // Check if the request is to login or a authorization request
    const authorization = window.sessionStorage.getItem("Authorization");
    if(request.url === RouteConstants.LOGIN_API_URL && request.email && request.password)
    {
      request.headers.Authorization = "Basic " + window.btoa(request.email + ':' + request.password);
    // If the user is not registering or browsing a store then add the Authorization header
    }else if(authorization){
      request.headers.Authorization = authorization;
    }
    return request
  }
)

export default api;