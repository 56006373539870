import { useState } from "react";

import card from '../../images/card.png'
import paypal from '../../images/paypal.webp'
import applePay from '../../images/applepay.webp'
import litecoin from '../../images/litecoin.webp'
import bitcoin from '../../images/bitcoin.webp'
import eth from '../../images/ethereum.webp'
import cashapp from '../../images/cashapp.webp'
import discord from '../../images/discord.webp'

import { createInvoice } from "../../services/invoice";

import { Link } from "react-router-dom";
import { AppConstants } from "../../constants/AppConstants";
import { getCookie, setCookie } from "../../util/cookies";
import { addNotif } from "../../util/showNotif";

import './checkout.css'
import { showElementById } from "../../util/hideShowElementById";

function Checkout({ product, orderAmount, setOrderId, checkoutType }) {
    const [paymentMethod, setPaymentMethod] = useState()

    const handleSubmit = async (e) => {
        e.preventDefault()

        document.getElementById("checkout-pay").classList.add("hidden");
        document.getElementById("loading-pay").classList.remove("hidden");
        if (!paymentMethod) {
            document.getElementById("s-pm-method-err").style.display = "block";

            document.getElementById("checkout-pay").classList.remove("hidden");
            document.getElementById("loading-pay").classList.add("hidden");
        } else if (paymentMethod === "JoinDiscord") {
            addNotif("Redirecting to discord server...", "success", 4000)
            setTimeout(() => {
                window.location.href = AppConstants.DISCORD_PAYMENT_INVITE

            }, 3000);
        } else {
            try 
            {
                const referrer = getCookie("referrer")
                const customerStatus = getCookie("CustomerStatus")

                const invoice = {
                    ipAddress: '0.0.0.0',
                    paymentMethod: paymentMethod,
                    referrer: referrer,
                    discordUsername: document.getElementById("s-pm-discord").value,
                    email: document.getElementById("s-pm-email").value,
                    quantity: orderAmount,
                    customerStatus: customerStatus
                }

                if (checkoutType === "Account") {
                    invoice.accountUsername = product.username
                } else {
                    invoice.productNameId = product.productNameId
                }

                const response = await createInvoice(invoice)
                if (response === null || response === "")
                    addNotif("Please refresh the page and try again. Make sure you are not on a VPN. If it still doesn't work join the ticket and create a ticket.", "error", 6000)
                else if (response === "CC_DISABLED")
                    addNotif("Card payments down. Join the discord server to pay with PayPal, Zelle, Venmo or LTC.", "error", 6000)
                else if (response === "Invalid_Access")
                    addNotif("You can't purchase under the minimum quantity.", "error", 6000)
                else{
                    if (response.includes("4WK4L3ET4E")) {
                        setCookie("CustomerStatus", "common", 365)
                    }
                    
                    if(paymentMethod === "CashApp"){
                        setOrderId(response)
                        showElementById("cashapp-popup")
                    } else {
                        window.location.href = response;
                    }
                }
                document.getElementById("checkout-pay").classList.remove("hidden");
                document.getElementById("loading-pay").classList.add("hidden");
            } catch (error) {
                console.log(error)
            }
        }
    }

    const closeModal = (id) => {
        document.querySelector(`#${id}`).style.display = "none"
        setPm()
        document.getElementById("checkout-form").reset();
    }

    const setPm = (pm) => {
        document.getElementById("checkout-pay").classList.remove("hidden");
        document.getElementById("loading-pay").classList.add("hidden");

        if (pm) {
            if (pm === 'PayPal') {
                document.getElementById('join-discord-msg').classList.remove('hidden')
                document.getElementById('PayPal').classList.add('hidden')
                document.getElementById('Crypto').classList.remove('s-c-pm-selected')
                document.getElementById('CreditCard').classList.remove('s-c-pm-selected')
                document.getElementById('CashApp').classList.remove('s-c-pm-selected')
            } else if (pm === 'Crypto') {
                document.getElementById('Crypto').classList.add('s-c-pm-selected')
                document.getElementById('CreditCard').classList.remove('s-c-pm-selected')
                document.getElementById('CashApp').classList.remove('s-c-pm-selected')
            } else if (pm === 'CreditCard') {
                setPaymentMethod(pm)
                document.getElementById('CreditCard').classList.add('s-c-pm-selected')
                document.getElementById('Crypto').classList.remove('s-c-pm-selected')
                document.getElementById('CashApp').classList.remove('s-c-pm-selected')
            } else if (pm === 'CashApp') {
                document.getElementById('CashApp').classList.add('s-c-pm-selected')
                document.getElementById('Crypto').classList.remove('s-c-pm-selected')
                document.getElementById('CreditCard').classList.remove('s-c-pm-selected')
            } else if (pm === 'JoinDiscord') {
                window.location.href = AppConstants.DISCORD_PAYMENT_INVITE;
            }
            setPaymentMethod(pm)
        } else {
            document.getElementById('CreditCard').classList.remove('s-c-pm-selected')
        }
    }

    return (
        <div id="s-c-purchase-modal-cont" onSubmit={handleSubmit}>
            <form id="checkout-form" className="s-c-purchase-modal s-c-item">
                <div>
                    <div className='s-c-oi-cont'>
                        <div className='s-c-oi-names'>
                            <span className='s-c-oi-n-span'><b>Product:</b></span>
                            <span className='s-c-oi-n-span'><b>Quantity:</b></span>
                            <span className='s-c-oi-n-span'><b>Price:</b></span>
                        </div>
                        <div className='s-c-oi-values'>
                            <span className='s-c-oi-n-span'>{product.title}</span>
                            <span className='s-c-oi-n-span'>{orderAmount}</span>
                            <span className='s-c-oi-n-span'>${(product.price * orderAmount).toFixed(2)}</span>
                        </div>
                    </div>
                    <h2 className="p-modal-headers">
                        Payment Methods
                    </h2>
                    <div className="s-p-payment-methods">
                        <div id="join-discord-msg" className="s-c-note hidden" onClick={() => setPm('JoinDiscord')}>
                            <div className="s-c-note-cont">
                                Click to join the Discord. Create a ticket for "Purchase" after joining.
                            </div>
                            <div className="s-c-note-btn">
                                <img className="s-c-discord" alt="Discord Icon" src={discord} />
                            </div>
                        </div>
                        <div id="PayPal" className="s-c-pm" onClick={() => setPm('PayPal')}>
                            <div className="pm-image-cont">
                                <img className='s-c-pm-img pp-img' src={paypal} alt="PayPal payments" />
                            </div>
                            <h4 className="s-c-pm-desc">PayPal (Must Join the discord)</h4>
                        </div>
                        <div id="CashApp" className="s-c-pm" onClick={() => setPm('CashApp')}>
                            <div className="pm-image-cont">
                                <img className='s-c-pm-img cashapp-img' src={cashapp} alt="PayPal payments" />
                            </div>
                            <h4 className="s-c-pm-desc">CashApp</h4>
                        </div>
                        <div id="Crypto" className="s-c-pm" onClick={() => setPm('Crypto')}>
                            <div className="pm-image-cont">
                                <img className='s-c-pm-img litecoin-img' alt="Litecoin Icon" src={litecoin} />
                                <img className='s-c-pm-img bitcoin-img' alt="Bitcoin Icon" src={bitcoin} />
                                <img className='s-c-pm-img eth-img' alt="Ethereum Icon" src={eth} />
                            </div>
                            <h4 className="s-c-pm-desc">LTC, BTC, ETH</h4>
                        </div>
                        <div id="CreditCard" className="s-c-pm" onClick={() => setPm('CreditCard')}>
                            <div className="pm-image-cont">
                                <img className='s-c-pm-img' src={card} alt="Credit Card, Debit Card, Apple Pay and Google Pay" />
                                <img className='s-c-pm-img apple-pay-img' src={applePay} alt="Credit Card, Debit Card, Apple Pay and Google Pay" />
                            </div>
                            <h4 className="s-c-pm-desc">Debit/Credit Card, Apple Pay, and more</h4>
                        </div>
                    </div>
                    <h3 id="s-pm-method-err" className="error-msg">Select Payment Method</h3>
                    <h2 className="p-modal-headers">
                        Delivery Info
                    </h2>
                    <input id="s-pm-email" className="pm-input" placeholder="Email the product will be sent to." type="email" required />
                    <input id="s-pm-discord" className="pm-input" placeholder="Discord username" type="text" required />
                    <h3 id="s-pm-email-err" className="error-msg">Enter Email</h3>
                    <h3 className="s-c-pm-back" onClick={event => closeModal('s-c-purchase-modal-cont')}>Back</h3>
                </div>
                <div>
                    <input id="checkout-pay" className="s-c-pm-pay" type="submit" value="Purchase" />
                    <div id="loading-pay" className="s-c-loading-pay hidden">
                        <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        <h4>Please Wait a Moment</h4>
                    </div>
                    <p className="terms-agreement">By clicking, you agree to our <Link to="/help/terms-of-service"><span className="terms-service-span">terms and services</span></Link></p>
                </div>
            </form>
        </div>
    )
}

export default Checkout;