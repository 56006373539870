import SlideShow from "./slideshow";

import transdiscord from '../../images/discord.webp'
import alexandsteve from '../../images/alexsteve.webp'
import trustpilot from '../../images/trustpilot.webp'
import logo from '../../images/logo.webp'

import { AppConstants } from "../../constants/AppConstants.js";
import { Link } from "react-router-dom";

function SlideShowCont()
{
    return (
        <div className="welcome-cont">
        <h1 className="shop-header"><strong>Minecraft Alt Shop</strong></h1>
            <h2 className="h-msg">The cheapest Minecraft alt shop with the highest quality products. Shop with trust and satisfaction with unbeatable customer service and lifetime warranties.</h2>
            <div id="slideshow-cont">
                <div className="slideshow-color">
                    <Link to={"/statsfinder"} id="slideshow-one" href="#stats-finder" className="slideshows stats-slide">
                        <img className="slideshows-image" src={logo} alt="Minecraft Alts Slideshow"/>
                        <div className="slideshows-words">
                            <h3 className="slideshow-header">
                                Hypixel Stat Account Finder
                            </h3>
                            <p className="stats-finder-desc">
                                Need an account in a specific gamemode on Hypixel? Introducing... stats finder! Search for profiles with stats on your desired gamemode on Hypixel. Including Bedwars, Skywars, Megawalls, Arcade, Duels and Pit with Skyblock coming very soon. Know exactly what you're paying for. Try it out!
                            </p>
                            <button className="search-button">
                                Search <i class="fas fa-search"></i>
                            </button>
                        </div>
                    </Link>
                    <SlideShow
                    id="slideshow-two"
                    href={AppConstants.WEB_URL}
                    img={alexandsteve}
                    header="Welcome to GreatAlts"
                    msg="We offer lifetime warranties, cheapest prices and the highest quality keys and alts. Shop with us today!" />
                    <SlideShow
                    id="slideshow-three"
                    href="https://www.trustpilot.com/review/altsgreat.com?sort=recency"
                    img={trustpilot}
                    header="Satisfaction Guaranteed"
                    msg="We have a 100% satisfaction rate on trustpilot and through this we can guarantee satisfaction when shopping with us." />
                    <SlideShow
                    id="slideshow-four"
                    href={AppConstants.DISCORD_REGULAR_INVITE}
                    img={transdiscord}
                    header="Join our Discord"
                    msg="Our discord server provides support for customers and anyone who has any questions about our products or services. We provide occasional updates, discounts and giveaways! Join now for free!" />
                </div>
            </div>
        </div>
    )
}

export default SlideShowCont;